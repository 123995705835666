import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asynceAppPage = Loadable({
	loader: () => import('./eApp'),
	loading: MyLoadingComponent
})
const eAppPage: Module = {
	name: 'eAppPage',
	routes: [
		{
			name: '营养e生app',
			path: '/eApp',
			component: asynceAppPage,//正式官网
		},
	],
}
export default eAppPage