import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncmerchantPage = Loadable({
	loader: () => import('./merchant'),
	loading: MyLoadingComponent
})
const merchantPage: Module = {
	name: 'merchantPage',
	routes: [
		{
			name: '商户后台',
			path: '/merchant',
			component: asyncmerchantPage,
		},
	],
}
export default merchantPage