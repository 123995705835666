import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncHealthInfo = Loadable({
	loader: () => import('./HealthInfo'),
	loading: MyLoadingComponent
})

const HealthInfo: Module = {
	name: 'groupmain',
	routes: [
		{
			name: '健康百科详情',
			path: '/healthInfo/:kid',
			component: asyncHealthInfo,
		},
	],
}
export default HealthInfo