import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncAboutUs = Loadable({
	loader: () => import('./AboutUs'),
	loading: MyLoadingComponent
})
const asyncAboutUsCooperation = Loadable({
	loader: () => import('./AboutUsCooperation'),
	loading: MyLoadingComponent
})
const AboutUs: Module = {
	name: 'groupmain',
	routes: [
		{
			name: '关于我们',
			path: '/aboutus',
			component: asyncAboutUs,//正式官网
		},
		{
			name: '关于我们',
			path: '/medical/aboutus',
			component: asyncAboutUs,
		}
	],
}
export default AboutUs