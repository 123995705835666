import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncLogin = Loadable({
	loader: () => import('./login/login'),
	loading: MyLoadingComponent
})
const asyncRegister = Loadable({
	loader: () => import('./register/register'),
	loading: MyLoadingComponent
})
const asyncGoodInfo = Loadable({
	loader: () => import('./goodInfo/index'),
	loading: MyLoadingComponent
})
const asyncNews= Loadable({
	loader: () => import('./news/index'),
	loading: MyLoadingComponent
})

const Medical: Module = {
	name: 'medical',
	routes: [
		{
			name: '登录',
			path: '/medical/login',
			component: asyncLogin,
        },
        {
			name: '注册',
			path: '/medical/register',
			component: asyncRegister,
        },
        {
			name: '商品信息',
			path: '/medical/goodInfo/:id',
			component: asyncGoodInfo,
		},
		{
			name: '医疗资讯',
			path: '/medical/news',
			component: asyncNews,
		},
	],
}
export default Medical