import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncHealthWiki = Loadable({
	loader: () => import('./HealthWiki'),
	loading: MyLoadingComponent
})

const HealthWiki: Module = {
	name: 'groupmain',
	routes: [
		{
			name: '健康百科',
			path: '/healthwiki',
			component: asyncHealthWiki,
		},
	],
}
export default HealthWiki