import React from 'react'
import 'services/transformSize'
import { RouterProvider } from 'services/route'
import modules from 'modules'

const App: React.FC = () => {
  return (
      <RouterProvider modules={modules}></RouterProvider>
  )
}

export default App
