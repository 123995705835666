import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncBusinessPage = Loadable({
	loader: () => import('./business'),
	loading: MyLoadingComponent
})
const BusinessPage: Module = {
	name: 'BusinessPage',
	routes: [
		{
			name: '商务合作',
			path: '/business',
			component: asyncBusinessPage,//正式官网
		},
	],
}
export default BusinessPage