import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncjfdfbPage = Loadable({
	loader: () => import('./jfdfb'),
	loading: MyLoadingComponent
})
const jfdfbPage: Module = {
	name: 'merchantPage',
	routes: [
		{
			name: 'jfdfb',
			path: '/jfdfb',
			component: asyncjfdfbPage,
		},
	],
}
export default jfdfbPage