import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncLegalStatement = Loadable({
	loader: () => import('./LegalStatement'),
	loading: MyLoadingComponent
})

const LegalStatement: Module = {
	name: 'groupmain',
	routes: [
		{
			name: '法律声明',
			path: '/statement',
			component: asyncLegalStatement,
		},
	],
}
export default LegalStatement