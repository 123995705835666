import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncHomePageMedical = Loadable({
	loader: () => import('./HomePageMedical'),
	loading: MyLoadingComponent
})

const HomePage: Module = {
	name: 'groupmain',
	routes: [
		{
			name: '互联网药品器械网页',
			path: '/medical',
			component: asyncHomePageMedical,
		},
	],
}
export default HomePage