import homepage from './officialwebsite/homepage' // 官网首页
import homepageMedical from './officialwebsite/homepage_medical' // 官网首页-药品器械版本
import healthwiki from './officialwebsite/healthwiki' // 健康百科
import healthinfo from './officialwebsite/healthinfo' // 健康百科
import aboutus from './officialwebsite/aboutus' // 关于我们
import legalstatement from './officialwebsite/legalstatement' // 法律声明
import business from './officialwebsite/business' //商务合作
import eApp from './officialwebsite/eApp' //营养e生app
import nutritionNews from './officialwebsite/nutritionNews' //营养头条
import nutritionNewsInfo from './officialwebsite/nutritionNewsInfo' //营养头条详情
import merchant from './officialwebsite/merchant' //商户后台
import jfdfb from './officialwebsite/jfdfb' //积分兑付宝
import medical from './officialwebsite/medical' //医疗相关


export default [
    homepage,
    homepageMedical,
    healthwiki,
    healthinfo,
    aboutus,
    legalstatement,
    business,
    medical,
    eApp,
    nutritionNews,
    nutritionNewsInfo,
    merchant,
    jfdfb


]