import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncNutritionNewsInfoPage = Loadable({
	loader: () => import('./nutritionNewsInfo'),
	loading: MyLoadingComponent
})
const NutritionNewsInfoPage: Module = {
	name: 'NutritionNewsInfoPage',
	routes: [
		{
			name: '营养头条详情',
			path: '/nutritionNewsInfo/:kid/:clssifyId',
			component: asyncNutritionNewsInfoPage,
		},
	],
}
export default NutritionNewsInfoPage