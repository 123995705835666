import MyLoadingComponent from 'components/loadingComponent/loadingComponent'
import Loadable from 'react-loadable'

const asyncHomePage = Loadable({
	loader: () => import('./HomePage'),
	loading: MyLoadingComponent
})
const HomePage: Module = {
	name: 'groupmain',
	routes: [
		{
			name: '官网首页',
			path: '/',
			component: asyncHomePage,
		},
	],
}
export default HomePage